<template>
  <div class="zy-image">
    <img
      :src="src"
      @click="clickImg"
    />
    <image-viewer
      v-if="preview && visible"
      :url-list="urlList"
      :on-close="handlePreviewClose"
    ></image-viewer>
  </div>
</template>

<script>
import ImageViewer from "element-ui/packages/image/src/image-viewer.vue"
export default {
  components: { ImageViewer },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    src: String,
    preview: Boolean,
  },
  computed: {
    urlList() {
      return [this.src]
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (val) {
            this.$refs
          }
        })
      },
    },
  },
  methods: {
    handlePreviewClose() {
      this.visible = false
    },
    clickImg() {
      if (this.preview) {
        this.visible = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.zy-image {
  & > img {
    width: 100%;
    height: 100%;
  }
}
</style>
